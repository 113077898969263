/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { TitleHolder } from "../../common/sharedComponents/styles/card.style";
import { StepperContentHolder } from "../vendorRegistration/vendorRegistration";
import AdminTableContent from "./components/adminTableContent";
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

// import { StyledOutlineButton } from "../vendorRegistration/components/StepMain";

import { useDispatch, useSelector } from "react-redux";
import {
  ApprovedVendorDownloadThunk,
  vendor_reg_uplaod_excel,
} from "./redux/adminTableSlice";
import { history } from "../../app/history";
import LocalStorage from "../../utilities/localStorage";
import { Search, GetAppRounded, UploadRounded } from "@mui/icons-material";
import vendor_excel from "../../assets/Vendors.xlsx";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
// import { Link } from "react-router-dom";

function AdminTable() {
  const [loading, setLoading] = React.useState(false);
  const [fileOpen, setFileOpen] = React.useState(false);
  const [search, setSearch] = React.useState();
  const [clicked, setClicked] = React.useState(false);
  const [filter, setFilter] = React.useState(1);
  const [data, setData] = React.useState("");
  const { approvedVendorBuffer } = useSelector((state) => state.pendinglist);
  const userData = LocalStorage.getItem("tata_login_user");
  const clientId = LocalStorage.getItem("client")?.id;
  const dispatch = useDispatch();
  const handleClose = () => {
    setFileOpen(false);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  if (approvedVendorBuffer) {
    try {
      const url = window.URL.createObjectURL(new Blob([approvedVendorBuffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "output.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("errrrr: ", error);
    }
  }
  const handleUpload = () => {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", data);
    dispatch(vendor_reg_uplaod_excel(formData)).then((response) => {
      if (response?.payload?.success) {
        setLoading(false);
        setFileOpen(false);
        history.push("/app/recomm-vcc");
      }
      setLoading(false);
      setFileOpen(false);
    });
    setLoading(false);
  };
  return (
    <div>
      <TitleHolder style={{ paddingBottom: "5px" }}>
        <Grid container spacing={2}>
          <Grid sx={{ whiteSpace: "nowrap" }} item xs={6}>
            Vendors
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "end",
              gap: "0.5rem",
            }}
            xs={6}
          >
            <Grid
              item
              sx={{ display: "flex", gap: "1rem", alignItems: "center" }}
            >
              {clientId === 2 && (
                <FormControl variant="standard" size="small">
                  <InputLabel>Filter</InputLabel>
                  <Select
                    value={filter}
                    onChange={handleFilterChange}
                    label="Filter"
                    // size="small"
                    sx={{ width: "75px" }}
                  >
                    <MenuItem value="1">All</MenuItem>
                    <MenuItem value="2">Push</MenuItem>
                    <MenuItem value="3">Not Push</MenuItem>
                  </Select>
                </FormControl>
              )}
              <TextField
                variant="outlined"
                size="small"
                label="Search field"
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                variant="contained"
                size="large"
                onClick={() => setClicked(true)}
              >
                <Search />
              </Button>
            </Grid>
            {userData.roles[0] === "super admin" ? (
              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <React.Fragment>
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "2rem",
                        height: "2.5rem",
                        width: "4.3rem",
                      }}
                      {...bindTrigger(popupState)}
                    >
                      +
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem onClick={popupState.close}>
                        <a
                          href={vendor_excel}
                          download
                          style={{
                            fontSize: "0.8rem",
                            textDecoration: "none",
                            color: "black",
                          }}
                        >
                          {<GetAppRounded color="primary" />} Download Excel
                        </a>
                      </MenuItem>
                      <MenuItem onClick={() => setFileOpen(true)}>
                        <span
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {<UploadRounded color="primary" />} Upload Excel
                        </span>
                      </MenuItem>
                      <MenuItem
                        onClick={() => dispatch(ApprovedVendorDownloadThunk())}
                      >
                        <span
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {<GetAppRounded color="primary" />} Downalod Approved
                          Vendors
                        </span>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </TitleHolder>

      <StepperContentHolder style={{ paddingTop: "5px" }}>
        <AdminTableContent
          search={search}
          clicked={clicked}
          setClicked={setClicked}
          filter={filter}
        />
      </StepperContentHolder>

      {/* add file dialog */}
      <Dialog open={fileOpen} aria-labelledby="draggable-dialog-title">
        <DialogTitle
          style={{ cursor: "move", fontWeight: "Bold" }}
          id="draggable-dialog-title"
        >
          Add Excel File
        </DialogTitle>

        <DialogContent>
          <DialogContentText>Add an excel file to upload</DialogContentText>

          <DialogContentText>
            <input
              type="file"
              fullWidth
              accept=".xlsx .xls .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => setData(e.target.files[0])}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ alignItems: "right" }}>
          <Button
            autoFocus
            onClick={(() => setData(""), handleClose)}
            style={{ border: "#4059e5 solid 1px", height: "30px" }}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              textTransform: "none",
              background: "#4059e5",
              width: "70px",
              height: "30px",
            }}
            color="primary"
            variant="contained"
            onClick={handleUpload}
            loading={loading}
            loadingPosition="start"
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AdminTable;
